import React, {useRef, useState, useEffect } from "react";
import useScene from '../../hooks/useScene';
import {useSpring, animated} from '@react-spring/three';

const Modele3D = ({ 
    chemin = "", 
    echelle = 1, 
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    tourne = false
}) => {
    const ref = useRef();
    const [estHover, hover] = useState(false);
    const scene = useScene(chemin)
    const {rotation: _rotation} = useSpring({rotation: tourne ? [
        rotation[0], rotation[1] - .5, rotation[2]] : rotation})

    return (
        <>
        {console.log(chemin)}
            <animated.primitive
                ref={ref}
                object={scene}
                position={position}
                rotation={_rotation}
                scale={echelle}
                onPointerOver={() => hover(true)}
                onPointerOut={() => hover(false)}
            />
        </>
    );
};

export default Modele3D;

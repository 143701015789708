import './Chargement.scss';

// Future icône de chargement des pages
export default function Chargement() {
    return(
        <div className="Chargement">
            <h6>Chargement</h6>
            <div className="rond"></div>
        </div>

    )
}